<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <div class="mt-5">

      <!-- <Breadcrumbs :title="`Add New Questions`" :items="items"/> -->
      <Snackbar ref="snackbar"/>

      <div class="mb-8">
        <ValidationObserver ref="observer">
          <v-form>
            <div>
              <div class="mt-7">
                <!-- <ValidationProvider :name="questions.instruction" rules="required" v-slot="{ errors }"> -->
                  <v-text-field
                    outlined
                    dense
                    v-model="questions.instruction"
                    label="Instructions"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                <!-- </ValidationProvider> -->
                
                <!-- AUDIO QUESTION -->
                <div class="body-2">Question</div>
                <v-card flat outlined class="mb-4">
                  <v-card-text>
                    <!-- <ValidationProvider :name="questions.question" rules="required" v-slot="{ errors }"> -->
                      <v-textarea
                        solo
                        flat
                        dense
                        rows="4"
                        class="px-5 mt-4"
                        style="width:700px"
                        v-model="questions.question"
                        placeholder="Tuliskan pertanyaan disini"
                        color="#F05326">
                      </v-textarea>
                    <!-- </ValidationProvider> -->
                    <v-row no-gutters style="max-width: 100%;justify-content: flex-end">
                      <audio-attachment
                        :src="questions.fileQuestion.media_data"
                        @update="
                          $set(questions.fileQuestion, 'media_data', {
                            ...questions.fileQuestion.media_data,
                            ...$event,
                          })
                        "
                      />
                    </v-row>
                  </v-card-text>
                </v-card>
                <!-- AUDIO CORRECT ANSWER -->

                <div class="body-2">Correct Answer</div>
                <v-card flat outlined class="mb-4">
                  <v-card-text>
                    <ValidationProvider :name="questions.correct_answer" rules="required" v-slot="{ errors }">
                      <v-text-field
                        solo
                        flat
                        dense
                        rows="2"
                        class="px-5 mt-4"
                        v-model="questions.correct_answer"
                        :error-messages="errors"
                        placeholder="Tuliskan Jawaban disini"
                        autocomplete="off"
                        color="#F05326">
                      </v-text-field>
                    </ValidationProvider>
                    <v-row no-gutters style="max-width: 100%;justify-content: flex-end">
                        <!-- :class="Object.keys(questions.fileAnswer.media_data).length == 0  || Object.keys(questions.fileAnswer.media_data.media).length == 0? 'float-right' : ''" -->
                      <audio-attachment
                        :src="questions.fileAnswer.media_data"
                        @update="
                          $set(questions.fileAnswer, 'media_data', {
                            ...questions.fileAnswer.media_data,
                            ...$event,
                          })
                        "
                      />
                    </v-row>
                  </v-card-text>
                </v-card>
                <!-- AUDIO CORRECT ANSWER END -->

                <div>
                  <ValidationProvider :name="questions.correct_explan" rules="required" v-slot="{ errors }">
                    <v-textarea
                      outlined
                      dense
                      v-model="questions.correct_explan"
                      :error-messages="errors"
                      label="Correct Answer Explanation"
                      autocomplete="off"
                      color="#F05326">
                    </v-textarea>
                  </ValidationProvider>
                  <ValidationProvider :name="questions.incorrect_explan" rules="required" v-slot="{ errors }">
                    <v-textarea
                      outlined
                      dense
                      v-model="questions.incorrect_explan"
                      :error-messages="errors"
                      label="Incorrect Answer Explanation"
                      autocomplete="off"
                      color="#F05326">
                    </v-textarea>
                  </ValidationProvider>
                  <ValidationProvider :name="`${questions.point}`" rules="required" v-slot="{ errors }">
                    <v-text-field
                      outlined
                      dense
                      v-model="questions.point"
                      :error-messages="errors"
                      type="number"
                      label="Point"
                      autocomplete="off"
                      color="#F05326">
                    </v-text-field>
                  </ValidationProvider>
                  <ValidationProvider :name="`${questions.timer}`" rules="required" v-slot="{ errors }">
                  <v-text-field
                    outlined
                    dense
                    v-model="questions.timer"
                    :error-messages="errors"
                    label="Timer"
                    type="number"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="mt-7">
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize white--text float-right"
                @click="setData"
                color="#F05326">
                Save Question
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run || questions.length < 1"
                class="text-capitalize black--text mr-5 float-right"
                @click="$router.push(`/questions/list/${$route.params.id_topic}/${$route.params.id_activity}`)"
                color="#F5F5F5">
                Cancel
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>

    </div>

  </div>
</template>

<script>
import { get, post, put , destroy, upload } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import AudioAttachment from "@/components/media/layout/AudioAttachment.vue";
export default {
  data() {
    return {
       /*
       * ANSWER record_sentence
       */
      questions: {},
      /*
      * ANSWER END 
      */
      panel: 0,
      items: [
        {
          text: 'Merekam Suara',
          disabled: false,
          href: `/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`,
        },
        {
          text: 'Add New Questions',
          disabled: true,
          href: '/activity/create',
        }
      ],
      process: {
        run: false,
        isDisabled: false,
      }
    }
  },
  watch: {},
  computed:{},
  components: { Breadcrumbs, Snackbar, AudioAttachment },
  created(){},
  mounted(){
    //  && this.$route.params.question_type == 'record_sentence'
    if(this.$route.params.id_question){
      this.fetch()
    }else {
      this.questions = {
        id: "", 
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        question: "",
        correct_answer: "",
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        timer:0,
        fileQuestion: {
          media_data:{}
        },
        fileAnswer: {
          media_data:{}
        },
      };
    }
  },
  methods:{
    async fetch(){
      this.$store.state.process.run = true
      await get(`bank/v1/question/detail/${this.$route.params.id_question}`)
      .then((response) => {
        let res = response.data
        console.log(res);
        if(res.status == 200){
          this.$store.state.process.run = false
          let fileQuestion = {
            media_data: {
              with_media: false,
              _id: "",
            }
          }
          let fileAnswer = {
            media_data: {
              with_media: false,
              _id: "",
            }
          }
          let question = {
            id: res.data.id,
            instruction : res.data.instruction.text,
            question : res.data.question.text,
            fileQuestion: {
              media_data: {
                with_media: true,
                _id: res.data.question.media.id,
                media: res.data.question.media
              }
            },
            correct_answer : res.data.correct_answer[0],
            correct_explan : res.data.explanation.true.text,
            incorrect_explan : res.data.explanation.false.text,
            fileAnswer: {
              media_data: {
                with_media:  res.data.hint.media.length > 0 ? true : false,
                _id: res.data.hint.media.length > 0 ? res.data.hint.media[0].id : "",
                media:  res.data.hint.media.length > 0 ? res.data.hint.media[0] : {}
              }
            },
            point : res.data.point,
            timer : res.data.timer.duration,
          }
          // console.log(question)
          this.questions = question
        }else {
          this.$store.state.process.run = false
        }
      })
    },
    /*
     * METHOD QUESTION
     */
    addQuestion() {
      this.questions = {
        id: "", 
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        question: "",
        correct_answer: "",
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        timer:0,
        fileQuestion: {
          media_data:{}
        },
        fileAnswer: {
          media_data:{}
        },
      };
    },
    confirmDelete(i){
      this.questions.splice(i,1)
    },
    /*
    * END METHOD QUESTION 
    */
    /**
     * SAVE DATA
    */
    async setData(){
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.$store.state.process.run = true
        let data = {}
        this.$store.state.process.run = true
        if (Object.keys(this.questions.fileAnswer.media_data).length == 0 && Object.keys(this.questions.fileQuestion.media_data).length == 0) {
          this.$store.state.process.run = false
          this.$refs.snackbar.open("error", `Media masih ada yang belum terisi`);
        }else {
          data.instruction_text = this.questions.instruction;
          data.instruction_media = "";
          data.type = "record_sentence";
          data.question_text = this.questions.question;
          data.question_media = this.questions.fileQuestion.media_data._id;
          data.point = parseInt(this.questions.point);
          data.timer= parseInt(this.questions.timer);
          data.correct_answer = [this.questions.correct_answer];
          data.hint = {
            text: this.questions.correct_answer,
            media: [this.questions.fileAnswer.media_data._id]
          }
          data.status = "publish"
          data.explanation = {
            true_text: this.questions.correct_explan,
            true_media: "",
            false_text: this.questions.incorrect_explan,
            false_media: ""
          }
          if (this.questions.id !== "") {
            this.save(`update`, `bank/v1/question/${this.questions.id}`, `api/v1/question/update/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.questions.id}` , data)
          }else {
            this.save(`create`, `bank/v1/question/`, `api/v1/question/add/${this.$route.params.id_topic}/${this.$route.params.id_activity}`, data)
          }
        }
      }else {
        this.$store.state.process.run = false
        this.$refs.snackbar.open("error", `Form Belum Valid`);
      }
    },
    async save(params, url_question, url_activity, data_send){
      this.$store.state.process.run = true
      if(params == `update`){
        await put(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            put(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                Object.assign(this.questions, {id: res.data.id})
                this.$refs.snackbar.open("#4CAF50", `New Question Updated Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Updated Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Updated Failed`);
          }
        })
      }else {
        await post(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            post(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                this.$refs.snackbar.open("#4CAF50", `New Question Added Succesfully`);
                Object.assign(this.questions, {id: res.data.id})
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Added Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Added Failed`);
          }
        })
      }
    },
    /**
     * SAVE DATA END 
    */
  },
}
</script>