<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <div class="mt-5">

      <!-- <Breadcrumbs :title="`Add New Questions`" :items="items"/> -->
      <Snackbar ref="snackbar"/>

      <div class="mb-8">
        <ValidationObserver ref="observer">
          <v-form>
            <div>
              <div class="mt-7">
                <!-- <ValidationProvider :name="questions.instruction" rules="required" v-slot="{ errors }"> -->
                  <v-text-field
                    outlined
                    dense
                    v-model="questions.instruction"
                    label="Instructions"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                <!-- </ValidationProvider> -->
                  <div class="body-2">Question</div>
                  <v-card flat outlined class="mb-4">
                    <v-card-text>
                      <!-- <ValidationProvider :name="questions.question" rules="required" v-slot="{ errors }"> -->
                        <v-text-field
                          solo
                          flat
                          dense
                          v-model="questions.question"
                          class="px-5 mt-4"
                          style="width:700px"
                          placeholder="Tuliskan pertanyaan disini"
                          autocomplete="off"
                          color="#F05326">
                        </v-text-field>
                      <!-- </ValidationProvider> -->
                      <v-row no-gutters style="max-width: 100%;justify-content: flex-end">
                        <image-attachment
                          :src="questions.fileQuestion.media_data"
                          @update="
                            $set(questions.fileQuestion, 'media_data', {
                              ...questions.fileQuestion.media_data,
                              ...$event,
                            })
                          "
                        />
                      </v-row>
                    </v-card-text>
                  </v-card>
                <!-- </ValidationProvider> -->
              </div>

              <div v-for="(item,index) in questions.options" :key="index">
                <ValidationProvider :name="item.text" rules="required" v-slot="{ errors }">
                    <!-- :readonly="!item.isEditing" -->
                  <v-text-field
                    outlined
                    dense
                    :error-messages="errors"
                    v-model="item.text"
                    :label="`Option ${index + 1}`"
                    autocomplete="off"
                    color="#F05326">
                    <v-icon
                      @click="removeOption(index,item)"
                      color="red"
                      slot="append-outer">
                      mdi-close
                    </v-icon>
                  </v-text-field>
                </ValidationProvider>
              </div>

              <div>
                <v-btn 
                  @click="addOptions()"
                  color="#F05326"
                  text 
                  dense 
                  small 
                  elevation="0">
                  <v-icon left>mdi-plus</v-icon>
                  Add Options
                </v-btn>
              </div>

              <!-- {{ questions.correct_answer }}
              {{ questions.selected }} -->

              <div class="mt-5">
                <div>Please select for Correct Answer here</div>
                <v-item-group class="my-3" multiple v-model="questions.correct_answer">
                  <v-item
                    v-for="(item,n) in questions.options"
                    :key="n"
                    @change="changeSelected(item,n)"
                    v-slot="{ active, toggle }">
                    <v-chip
                      class="mr-2"
                      active-class="orange--text text--darken-2"
                      :input-value="active"
                      @click="toggle">
                      <!-- <div> {{ selected[n] != undefined ? selected[n] : 0 }} {{ item.text }} </div> -->
                      <v-icon v-if="active" left>mdi-check</v-icon>
                      {{ item.text }}
                    </v-chip>
                  </v-item>
                </v-item-group>

                <!-- {{ questions.selectedData }} -->
                <div>Correct Answer</div>
                <!-- <ValidationProvider name="Correct Answer" rules="required" v-slot="{ errors }"> -->
                <v-text-field
                  outlined
                  color="#F05326"
                  readonly
                  dense>
                  <template v-slot:prepend-inner>
                    <div v-for="(item , index) in questions.selectedData" :key="index">
                      <!-- <div class="mx-1 body-1"> {{ item }}, </div> -->
                      <v-chip class="mx-1" small>{{item}}</v-chip>
                    </div>
                  </template>
                </v-text-field>
                <!-- </ValidationProvider> -->
                <ValidationProvider :name="questions.correct_explan" rules="required" v-slot="{ errors }">
                  <v-textarea
                    outlined
                    dense
                    v-model="questions.correct_explan"
                    :error-messages="errors"
                    label="Correct Answer Explanation"
                    autocomplete="off"
                    color="#F05326">
                  </v-textarea>
                </ValidationProvider>
                <ValidationProvider :name="questions.incorrect_explan" rules="required" v-slot="{ errors }">
                  <v-textarea
                    outlined
                    dense
                    v-model="questions.incorrect_explan"
                    :error-messages="errors"
                    label="Incorrect Answer Explanation"
                    autocomplete="off"
                    color="#F05326">
                  </v-textarea>
                </ValidationProvider>
                <ValidationProvider :name="`${questions.point}`" rules="required" v-slot="{ errors }">
                  <v-text-field
                    outlined
                    dense
                    v-model="questions.point"
                    :error-messages="errors"
                    label="Point"
                    type="number"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                </ValidationProvider>
                <ValidationProvider :name="`${questions.timer}`" rules="required" v-slot="{ errors }">
                  <v-text-field
                    outlined
                    dense
                    v-model="questions.timer"
                    :error-messages="errors"
                    label="Timer"
                    type="number"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                </ValidationProvider>
              </div>
            </div>
            <div class="mt-7">
              <v-btn
                dense
                depressed
                @click="setData"
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run || questions.length < 1"
                class="text-capitalize white--text float-right"
                color="#F05326">
                Save Question
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run || questions.length < 1"
                class="text-capitalize black--text mr-5 float-right"
                @click="$router.push(`/questions/list/${$route.params.id_topic}/${$route.params.id_activity}`)"
                color="#F5F5F5">
                Cancel
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import ImageAttachment from '@/components/media/layout/ImageAttachment.vue'
export default {
  data() {
    return {
      /*
       * ANSWER multiple_choice
       */
      dataAnswer: [],
      questions: {},
      /*
       * ANSWER END 
       */
      panel: 0,
      inputText: "",
      selected: [],
      items: [
        {
          text: 'Menyusun Kata',
          disabled: false,
          href: `/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`,
        },
        {
          text: 'Add New Questions',
          disabled: true,
          href: '/activity/create',
        }
      ],
      process:{
        run: false
      }
    }   
  },
  watch: {},
  computed:{},
  components: { Breadcrumbs, Snackbar, ImageAttachment },
  mounted(){
    // && this.$route.params.question_type == 'multiple_choice'
    if(this.$route.params.id_question){
      this.fetch()
    }else {
      this.questions = {
        id: "",
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        question: "",
        fileQuestion: {
          media_data:{}
        },
        selected: [],
        selectedData:[],
        correct_answer: [],
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        options: []
      }
    }
  },
  created(){},
  methods:{
    async fetch(){
      this.$store.state.process.run = true
      await get(`bank/v1/question/detail/${this.$route.params.id_question}`)
      .then((response) => {
        let res = response.data
        // console.log(res);
        if(res.status == 200){
          this.$store.state.process.run = false
          let fileQuestion = {
            media_data: {
              with_media: false,
              _id: "",
            }
          }
          let question = {
            id: res.data.id,
            instruction : res.data.instruction.text,
            question : res.data.question.text,
            fileQuestion: {
              media_data: {
                with_media: true,
                _id: res.data.question.media.id,
                media: res.data.question.media
              }
            },
            selectedData : res.data.correct_answer,
            correct_explan : res.data.explanation.true.text,
            incorrect_explan : res.data.explanation.false.text,
            point : res.data.point,
            timer : res.data.timer.duration,
            options: res.data.options
          }
          question.correct_answer = []
          question.selected = []
          res.data.options.forEach((item,i) => {
            question.correct_answer.push(i)
            question.selected.push(i)
            // this.selected.push(i)
          })
          console.log(question);
          this.questions = question
        }else {
          this.$store.state.process.run = false
        }
      })
    },

    /*
     * METHOD QUESTION
     */
    addQuestion() {
      this.questions = {
        id: "",
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        question: "",
        fileQuestion: {
          media_data:{}
        },
        correct_answer: [],
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        timer: 0,
        options: [
          {
            title: "Jawaban A",
            isEditing: true,
            text: "",
          }
        ]
      }
    },
    addOptions(){
      this.questions.options.push({
        title: "Jawaban A",
        isEditing: true,
        text: "",
      })
    },
    confirmDelete(idx){
      this.questions.splice(idx,1)
    },
    removeOption(index,item){
      // console.log(index);
      // console.log(item);
      // console.log('options data',this.questions.options);
      // console.log('options',this.questions.options[index]);
      // console.log('=======================================')
      // console.log('correct_answer data',this.questions.correct_answer);
      // console.log('correct_answer',this.questions.correct_answer[index]);
      // console.log('=======================================')
      // console.log('selected data',this.questions.selected);
      // console.log('selected',this.questions.selected[index]);
      // console.log('=======================================')
      // console.log('selectedData datanya',this.questions.selectedData);
      // console.log('selectedData',this.questions.selectedData.findIndex((element) => element == item.text));
      
      this.questions.options.splice(index, 1)
      this.questions.correct_answer = []
      this.questions.selected = []
      this.questions.selectedData = []

      // this.questions.correct_answer.splice(index,1)
      // let index_correct = this.questions.correct_answer.findIndex((element) => element == index)
      // console.log(index_correct);
      // if (index_correct != -1) {
      //   this.questions.correct_answer.splice(index_correct,1)
      // }

      // this.questions.selected.splice(index,1)
      // let index_selected = this.questions.selected.findIndex((element) => element == index)
      // console.log(index_selected);
      // if (index_selected != -1) {
      //   this.questions.selected.splice(index_selected,1)
      // }

      // let index_data = this.questions.selectedData.findIndex((element) => element == item.text)
      // console.log(index_data);
      // if (index_data != -1) {
      //   this.questions.selectedData.splice(index_data,1)
      // }
    },
    /*
    * METHOD QUESTION END
    */
    /*
    * SAVE DATA
    */
    isDuplicate(i, opt) {
      let data = this.questions[i].options.filter((item, index) => item.text.toLowerCase() == opt.text.toLowerCase()).length != 1
      return data
    },
    add_data_correct(i,index, value){
      this.questions[i].options = this.questions[i].options.filter((opt) => {
        opt.text = opt.text.trim();
        return opt.text != "";
      });
      this.questions[i].options.forEach((opt) => (opt.isEditing = false));
    },
    edit_data_correct(i,index,value){

      this.add_data_correct(i,index,value);
      this.questions[i].options.forEach((opt) => (opt.isEditing = false));
      this.questions[i].options[index].isEditing = true
    },
    /**
    * METHOD SAVE DATA
    */
    changeSelected(item,idx) {
      this.inputText = ""
      let pos = this.questions.selected.indexOf(idx)
      if (pos === -1) {
        this.questions.selected.push(idx)
        this.questions.selectedData.push(item.text)
      }else {
        this.questions.selected.splice(pos,1)
        this.questions.selectedData.splice(pos,1)
      }
      // pos === -1 ? ;this.selectedData.push(item.text) : this.selected.splice(pos,1)

      // console.log(this.selected);

      this.$emit('change', this.questions.selected);
    },
    async setData(){
      // console.log(this.questions.selectedData);
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.$store.state.process.run = true
        let data = {}
        this.$store.state.process.run = true
        if(this.questions.selectedData.length < 1){
          this.$store.state.process.run = false
          this.$refs.snackbar.open("error", `Correct Answer masih belum terisi`);
        }else {
          data.options = []
          this.questions.options.forEach((opt,index) => {
            data.options.push({
              media: "",
              text: opt.text
            })
          })
          data.instruction_text = this.questions.instruction;
          data.instruction_media = "";
          data.type = "multiple_choice";
          data.question_text = this.questions.question;
          data.question_media = this.questions.fileQuestion.media_data._id;
          data.point = parseInt(this.questions.point);
          data.correct_answer = this.questions.selectedData;
          data.status = "publish"
          data.explanation = {
            true_text: this.questions.correct_explan,
            true_media: "",
            false_text: this.questions.incorrect_explan,
            false_media: ""
          }
          if (this.questions.id !== "") {
            this.save(`update`, `bank/v1/question/${this.questions.id}`, `api/v1/question/update/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.questions.id}` , data)
          }else {
            this.save(`create`, `bank/v1/question/`, `api/v1/question/add/${this.$route.params.id_topic}/${this.$route.params.id_activity}`, data)
          }
        }
      }else{
        this.$store.state.process.run = false
        this.$refs.snackbar.open("error", `Form Belum Valid`);
      }
    },
    async save(params, url_question, url_activity, data_send){
      this.$store.state.process.run = true
      if(params == "update"){
        await put(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            put(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                Object.assign(this.questions, {id: res.data.id})
                this.$refs.snackbar.open("#4CAF50", `New Question Updated Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Updated Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Updated Failed`);
          }
        })
      }else {
        await post(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            post(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                Object.assign(this.questions, {id: res.data.id})
                this.$refs.snackbar.open("#4CAF50", `New Question Added Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Added Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Added Failed`);
          }
        })
      }
    },
    /**
    * METHOD SAVE DATA END
    */
  }
}
</script>
