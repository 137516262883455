var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'},[_c('div',{staticClass:"mt-5"},[_c('Snackbar',{ref:"snackbar"}),_c('div',{staticClass:"mb-8"},[_c('ValidationObserver',{ref:"observer"},[_c('v-form',[_c('div',[_c('div',{staticClass:"mt-7 mb-3"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Instructions","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.instruction),callback:function ($$v) {_vm.$set(_vm.questions, "instruction", $$v)},expression:"questions.instruction"}}),_c('ValidationProvider',{attrs:{"name":_vm.questions.paragraph,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","rows":"3","persistent-hint":"","hint":"Add formatting to your hints using markdown $[your hints]","error-messages":errors,"label":"Question","autocomplete":"off","color":"#F05326"},on:{"keyup":function($event){return _vm.markdown($event)}},model:{value:(_vm.questions.paragraph),callback:function ($$v) {_vm.$set(_vm.questions, "paragraph", $$v)},expression:"questions.paragraph"}})]}}])})],1),_vm._l((_vm.questions.options),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"subtitle-1 mb-1"},[_vm._v(" Option "+_vm._s(index + 1)+" ")]),_c('v-card',{staticClass:"mb-2",attrs:{"flat":"","outlined":""}},[_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('ValidationProvider',{attrs:{"name":item.text,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"width":"700px"},attrs:{"solo":"","flat":"","dense":"","placeholder":"Tulis jawaban disini","autocomplete":"off","color":"#F05326","error-messages":errors},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}})]}}],null,true)}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.confirmDeleteOptions(index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Option")])])],1),_c('v-spacer'),_c('v-row',{staticStyle:{"max-width":"100%","justify-content":"flex-end"},attrs:{"no-gutters":""}},[_c('audio-attachment',{attrs:{"src":item.fileAudio.media_data},on:{"update":function($event){return _vm.$set(item.fileAudio, 'media_data', Object.assign({}, item.fileAudio.media_data,
                          $event))}}}),_c('image-attachment',{attrs:{"src":item.fileImage.media_data},on:{"update":function($event){return _vm.$set(item.fileImage, 'media_data', Object.assign({}, item.fileImage.media_data,
                          $event))}}})],1)],1)],1)],1)}),_c('div',[_c('v-btn',{attrs:{"color":"#F05326","text":"","dense":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.addOptions()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Options ")],1)],1),_c('div',{staticClass:"mt-5"},[_c('ValidationProvider',{attrs:{"name":_vm.questions.correct_explan,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Correct Answer Explanation","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.correct_explan),callback:function ($$v) {_vm.$set(_vm.questions, "correct_explan", $$v)},expression:"questions.correct_explan"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.questions.incorrect_explan,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Incorrect Answer Explanation","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.incorrect_explan),callback:function ($$v) {_vm.$set(_vm.questions, "incorrect_explan", $$v)},expression:"questions.incorrect_explan"}})]}}])}),_c('ValidationProvider',{attrs:{"name":("" + (_vm.questions.point)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Point","type":"number","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.point),callback:function ($$v) {_vm.$set(_vm.questions, "point", $$v)},expression:"questions.point"}})]}}])}),_c('ValidationProvider',{attrs:{"name":("" + (_vm.questions.timer)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Timer","type":"number","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.timer),callback:function ($$v) {_vm.$set(_vm.questions, "timer", $$v)},expression:"questions.timer"}})]}}])})],1)],2),_c('div',{staticClass:"mt-7"},[_c('v-btn',{staticClass:"text-capitalize white--text float-right",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run,"color":"#F05326"},on:{"click":_vm.setData}},[_vm._v(" Save Question ")]),_c('v-btn',{staticClass:"text-capitalize black--text mr-5 float-right",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run || _vm.questions.length < 1,"color":"#F5F5F5"},on:{"click":function($event){return _vm.$router.push(("/questions/list/" + (_vm.$route.params.id_topic) + "/" + (_vm.$route.params.id_activity)))}}},[_vm._v(" Cancel ")])],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }