<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <div class="mt-5">

      <Breadcrumbs :title="!$route.params.id_question ? `Add New Questions` : `Update Question`" :items="items"/>
      <Snackbar ref="snackbar"/>

      <div class="mb-8">
        <ValidationObserver ref="observer">
          <v-form>
            <div>
              <v-expansion-panels flat v-model="panel">
                <v-expansion-panel expand v-model="panel" v-for="(item,i) in questions" :key="i" class="mb-3 rounded-lg" style="border: 1px solid #ddd">
                  <v-expansion-panel-header>
                    <span class="font-weight-bold text-h6"> Number {{ parseInt($route.params.question_number) }} </span>
                    <!-- <v-spacer></v-spacer>
                    <div class="text-right">
                      <v-btn
                        icon
                        small
                        @click.stop="confirmDelete(i)">
                        <v-icon color="red">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div> -->
                  </v-expansion-panel-header>
                  <v-divider></v-divider>
                  <v-expansion-panel-content>
                    <v-row class="mt-7 px-5">
                      <v-col cols="12" md="5">
                        <ValidationProvider :name="item.question_type" rules="required" v-slot="{ errors }">
                          <v-select
                            dense
                            flat
                            solo
                            @change="selected_type(i)"
                            :disabled="item.isDisabled"
                            :items="question_type"
                            item-text="text"
                            item-value="value"
                            label="Select your question type"
                            v-model="item.question_type"
                            :error-messages="errors">
                          </v-select>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <div class="subtitle-1 teal--text px-5">Jangan lupa klik tombol save pada setiap Question untuk menyimpan data Question</div>

                    <v-scroll-y-transition>
                      <v-alert
                        dense
                        outlined
                        text
                        dismissible
                        icon="mdi-alert-circle-outline"
                        color="#e74c3c"
                        class="mb-0 mt-2"
                        v-show="feedback.length > 0">
                        <div class="subtitle-2">{{ feedback }}</div>
                      </v-alert>
                    </v-scroll-y-transition>

                    <ConstructorWord
                      v-if="item.question_type == 'multiple_choice'"
                    />

                    <FillTheBlank
                      v-if="item.question_type == 'fill_the_blank'"
                    />

                    <Listening
                      v-if="item.question_type == 'single_choice'"
                    />

                    <Matching
                      v-if="item.question_type == 'matching'"
                    />

                    <Reading
                      v-if="item.question_type == 'single_choice_hint'"
                    />

                    <SentenceRecord
                      v-if="item.question_type == 'record_sentence'"
                    />

                    <Vocabulary
                      v-if="item.question_type == 'single_choice_image'"
                    />

                    <VoiceRecord
                      v-if="item.question_type == 'short_answer'"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>

            <!-- <div class="mt-7">
              <v-btn 
                outlined
                dense
                @click="addQuestion()"
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize"
                color="#F05326">
                <v-icon left>mdi-plus</v-icon>
                Add Question
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run || questions.length < 1"
                class="text-capitalize white--text float-right"
                color="#F05326">
                Submit
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run || questions.length < 1"
                class="text-capitalize black--text mr-5 float-right"
                @click="$router.push(`/questions/list/${$route.params.id_course}/${$route.params.id_topic}/${$route.params.id_activity}`)"
                color="#F5F5F5">
                Cancel
              </v-btn>
            </div> -->
          </v-form>
        </ValidationObserver>
      </div>

    </div>

  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import * as QuestionsType from './QuestionsType'
import FillTheBlank from '../CreateQuestion/FillTheBlank.vue'
export default {
  components: { 
    Breadcrumbs, 
    Snackbar,
    ConstructorWord: QuestionsType.ConstructorWord,
    FillTheBlank: QuestionsType.FillTheBlank,
    Listening: QuestionsType.Listening,
    Matching: QuestionsType.Matching,
    Reading: QuestionsType.Reading,
    SentenceRecord: QuestionsType.SentenceRecord,
    Vocabulary: QuestionsType.Vocabulary,
    VoiceRecord: QuestionsType.VoiceRecord
  },
  data() {
    return {
      questions: [],
      panel: 0,
      feedback: "",
      question_type: [
        {
          text: "Kosakata (Pilihan Gambar)",
          value: "single_choice_image"
        },
        {
          text: "Kosakata (Matching Gambar)",
          value: "matching"
        },
        {
          text: "Mendengarkan Audio",
          value: "single_choice"
        },
        {
          text: "Menyusun Kata/ Menerjemahkan",
          value: "multiple_choice"
        },
        {
          text: "Merekam Suara",
          value: "short_answer"
        },
        {
          text: "Merekam Suara (Conversation)",
          value: "record_sentence"
        },
        {
          text: "Membaca (Fill in the blank)",
          value: "fill_the_blank"
        },
        {
          text: "Membaca (Paragraph)",
          value: "single_choice_hint"
        }
      ],
      items: [
        {
          text: 'Questions',
          disabled: false,
          href: `/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`,
        },
        {
          text: !this.$route.params.id_question ? `Add New Questions` : `Update Question`,
          disabled: true,
          href: '/activity/create',
        }
      ],
    }
  },
  watch: {},
  computed:{},
  mounted(){
    if (this.$route.params.id_question) {
      this.fetch()
    }else{
      this.questions.push({})
    }
  },
  created(){},
  methods:{
    async fetch(){
      this.$store.state.process.run = true
      await get(`bank/v1/question/detail/${this.$route.params.id_question}`)
      .then((response) => {
        let res = response.data
        // console.log(res);
        if(res.status == 200){
          this.$store.state.process.run = false
          this.questions.push({
            question_type: res.data.type,
            isDisabled: false
          })
        }
      })
    },
    selected_type(i){
      if (this.questions[i].question_type == this.$route.params.question_type) {
        this.feedback = "Tambahkan Question yang berbeda pada saat di halaman Update"
        this.questions[i].question_type = ""
      }else{
        // console.log(this.questions[i]);
      }
    },
    addQuestion(){
      this.questions.push({})
    },
    confirmDelete(i){
      this.questions.splice(i,1)
    },
    submit(){
      this.$store.state.process.run = true
      this.$refs.snackbar.open("#4CAF50", `New Question Saved Succesfully`);
      setTimeout(() => {
        this.$store.state.process.run = false
        this.$router.push(`/questions/list/${$route.params.id_course}/${$route.params.id_topic}/${$route.params.id_activity}`)
      }, 2000);  
    }
  }
}
</script>

<style>
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #E0E0E0 !important;
}
</style>