var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'},[_c('div',{staticClass:"mt-5"},[_c('Snackbar',{ref:"snackbar"}),_c('div',{staticClass:"mb-8"},[_c('ValidationObserver',{ref:"observer"},[_c('v-form',[_c('div',[_c('div',{staticClass:"mt-7"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Instructions","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.instruction),callback:function ($$v) {_vm.$set(_vm.questions, "instruction", $$v)},expression:"questions.instruction"}}),_c('div',[_vm._v("Question")]),_c('v-card',{staticClass:"mb-4",attrs:{"flat":"","outlined":""}},[_c('v-card-text',[_c('v-textarea',{staticClass:"px-5 mt-4",staticStyle:{"width":"700px"},attrs:{"solo":"","flat":"","dense":"","rows":"4","placeholder":"Tuliskan pertanyaan disini","color":"#F05326"},model:{value:(_vm.questions.question),callback:function ($$v) {_vm.$set(_vm.questions, "question", $$v)},expression:"questions.question"}}),_c('v-row',{staticStyle:{"max-width":"100%","justify-content":"flex-end"},attrs:{"no-gutters":""}},[_c('audio-attachment',{attrs:{"src":_vm.questions.fileQuestion.media_data},on:{"update":function($event){return _vm.$set(_vm.questions.fileQuestion, 'media_data', Object.assign({}, _vm.questions.fileQuestion.media_data,
                          $event))}}})],1)],1)],1),_vm._l((_vm.questions.options),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"text-right caption mr-10"},[_vm._v("Correct Answer")]),_c('ValidationProvider',{attrs:{"name":item.text,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"options",attrs:{"outlined":"","dense":"","error-messages":errors,"label":("Option " + (index + 1)),"autocomplete":"off","color":"#F05326"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('ValidationProvider',{attrs:{"name":_vm.questions.selectedValue,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors},model:{value:(_vm.questions.selectedValue),callback:function ($$v) {_vm.$set(_vm.questions, "selectedValue", $$v)},expression:"questions.selectedValue"}},[_c('v-radio',{attrs:{"color":"#F05326","value":item.text}})],1)]}}],null,true)})]},proxy:true}],null,true),model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}},[_c('v-icon',{staticClass:"mt-4",attrs:{"slot":"append-outer","color":"red"},on:{"click":function($event){return _vm.removeOption(index)}},slot:"append-outer"},[_vm._v(" mdi-close ")])],1)]}}],null,true)})],1)}),_c('div',[_c('v-btn',{attrs:{"color":"#F05326","text":"","dense":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.addOptions()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Options ")],1),_c('v-btn',{staticClass:"float-right",attrs:{"color":"red","text":"","dense":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.clearAnswer()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Clear Answer ")],1)],1),_c('div',{staticClass:"mt-5"},[_c('ValidationProvider',{attrs:{"name":_vm.questions.correct_explan,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Correct Answer Explanation","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.correct_explan),callback:function ($$v) {_vm.$set(_vm.questions, "correct_explan", $$v)},expression:"questions.correct_explan"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.questions.incorrect_explan,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Incorrect Answer Explanation","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.incorrect_explan),callback:function ($$v) {_vm.$set(_vm.questions, "incorrect_explan", $$v)},expression:"questions.incorrect_explan"}})]}}])}),_c('ValidationProvider',{attrs:{"name":("" + (_vm.questions.point)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"number","error-messages":errors,"label":"Point","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.point),callback:function ($$v) {_vm.$set(_vm.questions, "point", $$v)},expression:"questions.point"}})]}}])}),_c('ValidationProvider',{attrs:{"name":("" + (_vm.questions.timer)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Timer","type":"number","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.timer),callback:function ($$v) {_vm.$set(_vm.questions, "timer", $$v)},expression:"questions.timer"}})]}}])})],1)],2)]),_c('div',{staticClass:"mt-7"},[_c('v-btn',{staticClass:"text-capitalize white--text float-right",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run,"color":"#F05326"},on:{"click":_vm.setData}},[_vm._v(" Save Question ")]),_c('v-btn',{staticClass:"text-capitalize black--text mr-5 float-right",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run || _vm.questions.length < 1,"color":"#F5F5F5"},on:{"click":function($event){return _vm.$router.push(("/questions/list/" + (_vm.$route.params.id_topic) + "/" + (_vm.$route.params.id_activity)))}}},[_vm._v(" Cancel ")])],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }