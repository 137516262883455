var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'},[_c('div',{staticClass:"mt-5"},[_c('Snackbar',{ref:"snackbar"}),_c('div',{staticClass:"mb-8"},[_c('ValidationObserver',{ref:"observer"},[_c('v-form',[_c('div',[_c('div',{staticClass:"mt-7"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Instructions","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.instruction),callback:function ($$v) {_vm.$set(_vm.questions, "instruction", $$v)},expression:"questions.instruction"}}),_c('div',[_vm._v("Question")]),_c('v-card',{staticClass:"mb-4",attrs:{"flat":"","outlined":""}},[_c('v-card-text',[_c('v-text-field',{staticClass:"px-5 mt-4",attrs:{"solo":"","flat":"","dense":"","placeholder":"Tuliskan pertanyaan disini","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.question),callback:function ($$v) {_vm.$set(_vm.questions, "question", $$v)},expression:"questions.question"}}),_c('v-row',{staticStyle:{"max-width":"100%","justify-content":"flex-end"},attrs:{"no-gutters":""}},[_c('audio-attachment',{attrs:{"src":_vm.questions.fileQuestion.media_data},on:{"update":function($event){return _vm.$set(_vm.questions.fileQuestion, 'media_data', Object.assign({}, _vm.questions.fileQuestion.media_data,
                          $event))}}})],1)],1)],1),_c('ValidationProvider',{attrs:{"name":_vm.questions.correct_answer,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Correct Answer","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.correct_answer),callback:function ($$v) {_vm.$set(_vm.questions, "correct_answer", $$v)},expression:"questions.correct_answer"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.questions.correct_explan,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Correct Answer Explanation","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.correct_explan),callback:function ($$v) {_vm.$set(_vm.questions, "correct_explan", $$v)},expression:"questions.correct_explan"}})]}}])}),_c('div',[_vm._v("Incorrect Explanation Answer")]),_c('v-card',{staticClass:"mb-4",attrs:{"flat":"","outlined":""}},[_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":_vm.questions.incorrect_explan,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-textarea',{staticClass:"px-5 mt-4",attrs:{"solo":"","flat":"","dense":"","rows":"2","error-messages":errors,"placeholder":"Tuliskan Jawaban disini","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.incorrect_explan),callback:function ($$v) {_vm.$set(_vm.questions, "incorrect_explan", $$v)},expression:"questions.incorrect_explan"}})]}}])}),_c('v-row',{staticStyle:{"max-width":"100%","justify-content":"flex-end"},attrs:{"no-gutters":""}},[_c('audio-attachment',{attrs:{"src":_vm.questions.fileAnswer.media_data},on:{"update":function($event){return _vm.$set(_vm.questions.fileAnswer, 'media_data', Object.assign({}, _vm.questions.fileAnswer.media_data,
                          $event))}}})],1)],1)],1),_c('ValidationProvider',{attrs:{"name":("" + (_vm.questions.point)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"type":"number","label":"Point","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.point),callback:function ($$v) {_vm.$set(_vm.questions, "point", $$v)},expression:"questions.point"}})]}}])}),_c('ValidationProvider',{attrs:{"name":("" + (_vm.questions.timer)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Timer","type":"number","autocomplete":"off","color":"#F05326"},model:{value:(_vm.questions.timer),callback:function ($$v) {_vm.$set(_vm.questions, "timer", $$v)},expression:"questions.timer"}})]}}])})],1)]),_c('div',{staticClass:"mt-7"},[_c('v-btn',{staticClass:"text-capitalize white--text float-right",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run,"color":"#F05326"},on:{"click":_vm.setData}},[_vm._v(" Save Question ")]),_c('v-btn',{staticClass:"text-capitalize black--text mr-5 float-right",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run || _vm.questions.length < 1,"color":"#F5F5F5"},on:{"click":function($event){return _vm.$router.push(("/questions/list/" + (_vm.$route.params.id_topic) + "/" + (_vm.$route.params.id_activity)))}}},[_vm._v(" Cancel ")])],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }