<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <div class="mt-5">

      <!-- <Breadcrumbs :title="`Add New Questions`" :items="items"/> -->
      <Snackbar ref="snackbar"/>

      <div class="mb-8">
        <ValidationObserver ref="observer">
          <v-form>
            <div>
              <div class="mt-7">
                <!-- <ValidationProvider :name="questions.instruction" rules="required" v-slot="{ errors }"> -->
                  <v-text-field
                    outlined
                    dense
                    v-model="questions.instruction"
                    label="Instructions"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                <!-- </ValidationProvider> -->
                <!-- <ValidationProvider :name="questions.question" rules="required" v-slot="{ errors }"> -->
                  <v-text-field
                    outlined
                    dense
                    v-model="questions.question"
                    label="Question"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                <!-- </ValidationProvider> -->
              </div>
              
              <!-- ANSWER -->
              <div v-for="(item,index) in questions.options" :key="index">
                <div class="subtitle-1 mb-1 d-flex justify-space-between">
                  <div> Option {{ index + 1 }}</div>
                  <div class="text-right">Correct Answer</div>
                </div>
                <v-card flat outlined class="mb-2">
                  <v-card-text>
                    <div class="d-flex">
                      <ValidationProvider :name="item.text" rules="required" v-slot="{ errors }">
                        <v-text-field
                          solo
                          flat
                          dense
                          style="width:600px"
                          :error-messages="errors"
                          placeholder="Tulis jawaban disini"
                          autocomplete="off"
                          color="#F05326"
                          v-model="item.text">
                        </v-text-field>
                      </ValidationProvider>
                      <v-spacer></v-spacer>
                      <v-tooltip top>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn 
                            v-on="on"
                            v-bind="attrs"
                            icon
                            class="mt-4"
                            color="red"
                            @click="confirmDeleteOptions(index)">
                            <v-icon>
                              mdi-trash-can-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Delete Option</span>
                      </v-tooltip>
                      <ValidationProvider :name="questions.selectedValue" rules="required" v-slot="{ errors }">
                        <v-radio-group :error-messages="errors" v-model="questions.selectedValue">
                          <v-radio color="#F05326" :value="item.text"></v-radio>
                        </v-radio-group>
                      </ValidationProvider>
                    </div>
                    <v-spacer></v-spacer>

                    <v-row no-gutters style="max-width: 100%;justify-content: flex-end">
                      <audio-attachment
                        :src="item.fileAudio.media_data"
                        @update="
                          $set(item.fileAudio, 'media_data', {
                            ...item.fileAudio.media_data,
                            ...$event,
                          })
                        "
                      />
                      <image-attachment
                        :src="item.fileImage.media_data"
                        @update="
                          $set(item.fileImage, 'media_data', {
                            ...item.fileImage.media_data,
                            ...$event,
                          })
                        "
                      />
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>

              <div>
                <v-btn 
                  @click="addOptions()"
                  color="#F05326"
                  text 
                  dense 
                  small 
                  elevation="0">
                  <v-icon left>mdi-plus</v-icon>
                  Add Options
                </v-btn>
                <v-btn 
                  @click="clearAnswer()"
                  color="red"
                  class="float-right"
                  text 
                  dense 
                  small 
                  elevation="0">
                  <v-icon left>mdi-close</v-icon>
                  Clear Answer
                </v-btn>
              </div>
              
              <div class="mt-5">
                <ValidationProvider :name="questions.correct_explan" rules="required" v-slot="{ errors }">
                  <v-textarea
                    outlined
                    dense
                    v-model="questions.correct_explan"
                    :error-messages="errors"
                    label="Correct Answer Explanation"
                    autocomplete="off"
                    color="#F05326">
                  </v-textarea>
                </ValidationProvider>
                <ValidationProvider :name="questions.incorrect_explan" rules="required" v-slot="{ errors }">
                  <v-textarea
                    outlined
                    dense
                    v-model="questions.incorrect_explan"
                    :error-messages="errors"
                    label="Incorrect Answer Explanation"
                    autocomplete="off"
                    color="#F05326">
                  </v-textarea>
                </ValidationProvider>
                <ValidationProvider :name="`${questions.point}`" rules="required" v-slot="{ errors }">
                  <v-text-field
                    outlined
                    dense
                    v-model="questions.point"
                    type="number"
                    :error-messages="errors"
                    label="Point"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                </ValidationProvider>
                <ValidationProvider :name="`${questions.timer}`" rules="required" v-slot="{ errors }">
                  <v-text-field
                    outlined
                    dense
                    v-model="questions.timer"
                    :error-messages="errors"
                    label="Timer"
                    type="number"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                </ValidationProvider>
              </div>
            </div>
            <div class="mt-7">
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize white--text float-right"
                @click="setData"
                color="#F05326">
                Save Question
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run || questions.length < 1"
                class="text-capitalize black--text mr-5 float-right"
                @click="$router.push(`/questions/list/${$route.params.id_topic}/${$route.params.id_activity}`)"
                color="#F5F5F5">
                Cancel
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>

    </div>
  </div>
</template>

<script>
import { get, post, put , destroy, upload } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import AudioAttachment from "@/components/media/layout/AudioAttachment.vue";
import ImageAttachment from '@/components/media/layout/ImageAttachment.vue'
export default {
  data() {
    return { 
      /*
       * ANSWER  single_choice_image
       */
      questions: {},
      /*
       * ANSWER END 
       */
      panel: 0,
      items: [
        {
          text: 'Kosakata (Pilihan Gambar)',
          disabled: false,
          href: `/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`,
        },
        {
          text: 'Add New Questions',
          disabled: true,
          href: '/activity/create',
        }
      ],
      process:{
        run: false
      }
    }   
  },
  watch: {},
  computed:{},
  components: { Breadcrumbs, Snackbar, AudioAttachment, ImageAttachment },
  mounted(){
    //  && this.$route.params.question_type == 'single_choice_image'
    if(this.$route.params.id_question){
      this.fetch()
    }else {
      this.questions = {
        id: "", 
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        question: "",
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        timer:0,
        selectedValue:"",
        options:[
          {
            title: "Jawaban A",
            text: "",
            fileImage:{
              media_data: {}
            },
            fileAudio:{
              media_data: {}
            },
          }
        ]
      }
    }
  },
  created(){},
  methods:{
    async fetch(){
      this.$store.state.process.run = true
      await get(`bank/v1/question/detail/${this.$route.params.id_question}`)
      .then((response) => {
        let res = response.data
        if(res.status == 200){
          this.$store.state.process.run = false
          let question = {
            id: res.data.id,
            instruction : res.data.instruction.text,
            question : res.data.question.text,
            selectedValue : res.data.correct_answer[0],
            correct_explan : res.data.explanation.true.text,
            incorrect_explan : res.data.explanation.false.text,
            point : res.data.point,
            timer : res.data.timer.duration,
          }
          question.options = []

          res.data.options.forEach((opt,i) => {
            let fileImage = {
              media_data: {
                with_media: false,
                _id: "",
              }
            }
            let fileAudio = {
              media_data: {
                with_media: false,
                _id: "",
              }
            }
            opt.media.forEach((med,idx) => {
              if (med.type == "image") {
                fileImage = {
                  media_data: {
                    with_media: true,
                    _id: med.id,
                    media: med
                  }
                }
              }

              if (med.type == "audio") {
                fileAudio = {
                  media_data: {
                    with_media: true,
                    _id: med.id,
                    media: med
                  }
                }
              }
            })
            question.options.push({
              text: opt.text,
              fileImage,
              fileAudio
            })
          })
          // console.log(question)
          this.questions = question
        }else {
          this.$store.state.process.run = false
        }
      })
    },
    /*
     * METHOD QUESTION
     */
    addQuestion() {
      this.questions = {
        id: "", 
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        question: "",
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        timer:0,
        selectedValue:"",
        options:[
          {
            title: "Jawaban A",
            text: "",
            fileImage:{
              media_data: {}
            },
            fileAudio:{
              media_data: {}
            },
          }
        ]
      }
    },
    addOptions(idx){
      this.questions.options.push({
        title: "Jawaban A",
        text: "",
        fileImage:{
          media_data: {}
        },
        fileAudio:{
          media_data: {}
        },
      })
    },
    confirmDelete(idx){
      this.questions.splice(idx,1)
    },
    confirmDeleteOptions(index){
      this.questions.options.splice(index,1)
    },
    clearAnswer(i){
      this.$refs.observer.reset()
      this.questions.selectedValue = ""
    },
    /*
    * END METHOD QUESTION 
    */
    /*
    * METHOD SAVE DATA
    */
    async setData(){
      const isValid = await this.$refs.observer.validate()
        if (isValid) {
          this.$store.state.process.run = true
          let data = {}
            this.$store.state.process.run = true
            data.options = []
            this.questions.options.forEach((opt,index) => {
              data.options.push({
                media: [opt.fileImage.media_data._id,opt.fileAudio.media_data._id],
                text: opt.text
              })
            })
            data.instruction_text = this.questions.instruction;
            data.instruction_media = "";
            data.type = "single_choice_image";
            data.question_text = this.questions.question;
            data.question_media = "";
            data.point = parseInt(this.questions.point);
            data.timer=parseInt(this.questions.timer);
            data.correct_answer = [this.questions.selectedValue];
            data.status = "publish"
            data.explanation = {
              true_text: this.questions.correct_explan,
              true_media: "",
              false_text: this.questions.incorrect_explan,
              false_media: ""
            }

            if (this.questions.id !== "") {
              this.save(`update`, `bank/v1/question/${this.questions.id}`, `api/v1/question/update/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.questions.id}` , data)
            }else {
              this.save(`create`, `bank/v1/question/`, `api/v1/question/add/${this.$route.params.id_topic}/${this.$route.params.id_activity}`, data)
            }
        }else{
          this.$store.state.process.run = false
          this.$refs.snackbar.open("error", `Form Belum Valid`);
        }
    },
    async save(params, url_question, url_activity, data_send){
      this.$store.state.process.run = true
      if(params == `update`){
        await put(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            put(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                Object.assign(this.questions, {id: res.data.id})
                this.$refs.snackbar.open("#4CAF50", `New Question Updated Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Updated Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Updated Failed`);
          }
        })
      }else {
        await post(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            post(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                Object.assign(this.questions, {id: res.data.id})
                this.$refs.snackbar.open("#4CAF50", `New Question Added Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Added Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Added Failed`);
          }
        })
      }
    },
    /**
    * METHOD SAVE DATA END
    */
  }
}
</script>

<style>

</style>