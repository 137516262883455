<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <div class="mt-5">

      <!-- <Breadcrumbs :title="`Add New Questions`" :items="items"/> -->
      <Snackbar ref="snackbar"/>

      <div class="mb-8">
        <ValidationObserver ref="observer">
          <v-form>
            <div>
              <div class="mt-7 mb-3">
                <!-- <ValidationProvider :name="questions.instruction" rules="required" v-slot="{ errors }"> -->
                  <v-text-field
                    outlined
                    dense
                    v-model="questions.instruction"
                    label="Instructions"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                <!-- </ValidationProvider> -->
                <ValidationProvider :name="questions.paragraph" rules="required" v-slot="{ errors }">
                  <v-textarea
                    outlined
                    dense
                    rows="3"
                    class="mb-2"
                    @keyup="markdown($event)"
                    persistent-hint
                    hint="Add formatting to your hints using markdown $[your hints]"
                    v-model="questions.paragraph"
                    :error-messages="errors"
                    label="Paragraph"
                    autocomplete="off"
                    color="#F05326">
                  </v-textarea>
                </ValidationProvider>
                <!-- <ValidationProvider :name="questions.question" rules="required" v-slot="{ errors }"> -->
                  <v-text-field
                    outlined
                    dense
                    v-model="questions.question"
                    label="Question"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                <!-- </ValidationProvider> -->
              </div>

              <div v-for="(items,index) in questions.options" :key="index">
                <div class="caption text-right">Correct Answer</div>
                <ValidationProvider :name="items.text" rules="required" v-slot="{ errors }">
                  <v-text-field
                    outlined
                    dense
                    :error-messages="errors"
                    v-model="items.text"
                    :label="`Option ${index + 1}`"
                    autocomplete="off"
                    color="#F05326">
                    <template v-slot:append>
                      <ValidationProvider :name="questions.selectedValue" rules="required" v-slot="{ errors }">
                        <v-radio-group class="py-0 pt-0 pa-0" :error-messages="errors" v-model="questions.selectedValue">
                          <v-radio color="#F05326" :value="items.text"></v-radio>
                        </v-radio-group>
                      </ValidationProvider>
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </div>

              <div>
                <v-btn 
                  @click="addOptions()"
                  color="#F05326"
                  text 
                  dense 
                  small 
                  elevation="0">
                  <v-icon left>mdi-plus</v-icon>
                  Add Options
                </v-btn>
                <v-btn 
                  @click="clearAnswer()"
                  color="red"
                  class="float-right"
                  text 
                  dense 
                  small 
                  elevation="0">
                  <v-icon left>mdi-close</v-icon>
                  Clear Answer
                </v-btn>
              </div>

              <div class="mt-5">
                <ValidationProvider :name="questions.correct_explan" rules="required" v-slot="{ errors }">
                  <v-textarea
                    outlined
                    dense
                    v-model="questions.correct_explan"
                    :error-messages="errors"
                    label="Correct Answer Explanation"
                    autocomplete="off"
                    color="#F05326">
                  </v-textarea>
                </ValidationProvider>
                <ValidationProvider :name="questions.incorrect_explan" rules="required" v-slot="{ errors }">
                  <v-textarea
                    outlined
                    dense
                    v-model="questions.incorrect_explan"
                    :error-messages="errors"
                    label="Incorrect Answer Explanation"
                    autocomplete="off"
                    color="#F05326">
                  </v-textarea>
                </ValidationProvider>
                <ValidationProvider :name="`${questions.point}`" rules="required" v-slot="{ errors }">
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    v-model="questions.point"
                    :error-messages="errors"
                    label="Point"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                </ValidationProvider>
                <ValidationProvider :name="`${questions.timer}`" rules="required" v-slot="{ errors }">
                  <v-text-field
                    outlined
                    dense
                    v-model="questions.timer"
                    :error-messages="errors"
                    label="Timer"
                    type="number"
                    autocomplete="off"
                    color="#F05326">
                  </v-text-field>
                </ValidationProvider>
              </div>
            </div>
            <div class="mt-7">
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run"
                class="text-capitalize white--text float-right"
                @click="setData"
                color="#F05326">
                Save Question
              </v-btn>
              <v-btn
                dense
                depressed
                :loading="$store.state.process.run"
                :disabled="$store.state.process.run || questions.length < 1"
                class="text-capitalize black--text mr-5 float-right"
                @click="$router.push(`/questions/list/${$route.params.id_topic}/${$route.params.id_activity}`)"
                color="#F5F5F5">
                Cancel
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>
      
    </div>

  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
export default {
  data() {
    return {
       /*
       * ANSWER single_choice_hint
       */
      questions: {},
      timer:{},
      /*
       * ANSWER END 
       */
      panel: 0,
      process: {
        run: false
      },
      items: [
        {
          text: 'Reading',
          disabled: false,
          href: `/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`,
        },
        {
          text: 'Add New Questions',
          disabled: true,
          href: '/activity/create',
        }
      ],
    }
  },
  watch: {},
  computed:{},
  components: { Breadcrumbs, Snackbar },
  created(){},
  mounted(){
    //  && this.$route.params.question_type == 'single_choice_hint'
    if(this.$route.params.id_question){
      this.fetch()
    }else {
      this.questions = {
        id: "", 
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        paragraph: "",
        question: "",
        selectedValue: "",
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        timer:0,
        options:[
          {
            title: "Jawaban A",
            text: "",
          }
        ]
      };
    }
  },
  methods:{
    async fetch(){
      this.$store.state.process.run = true
      await get(`bank/v1/question/detail/${this.$route.params.id_question}`)
      .then((response) => {
        let res = response.data
        // console.log(res);
        if(res.status == 200){
          this.$store.state.process.run = false
          let question = {
            id: res.data.id,
            instruction : res.data.instruction.text,
            paragraph : res.data.hint.text, 
            question : res.data.question.text,
            selectedValue : res.data.correct_answer[0],
            correct_explan : res.data.explanation.true.text,
            incorrect_explan : res.data.explanation.false.text,
            point : res.data.point,
            timer : res.data.timer.duration,
            options: res.data.options
          }
          // console.log(question)
          this.questions = question
        }else {
          this.$store.state.process.run = false
        }
      })
    },
    /*
     * METHOD QUESTION
     */
    addQuestion() {
      this.questions = {
        id: "", 
        name: `Nomor ${this.questions.length + 1}`,
        instruction: "",
        paragraph: "",
        question: "",
        selectedValue: "",
        correct_explan: "",
        incorrect_explan: "",
        point: 0,
        timer:0,
        options:[
          {
            title: "Jawaban A",
            text: "",
          }
        ]
      };
    },
    addOptions(idx){
      this.questions.options.push(
        {
          title: "Jawaban C",
          text: "",
        },
      )
    },
    confirmDelete(i){
      this.questions.splice(i,1)
    },
    clearAnswer(i){
      this.$refs.observer.reset()
      this.questions.selectedValue = ""
    },
    markdown(e){
      // console.log(e);
      if (e.keyCode == 52 || e.keyCode == 219 || e.keyCode == 221) {
        // console.log('markdown looh ');
      }
      if (e.keyCode == 32) {
        // console.log('Spasi looh');
      }
    },
    /*
    * END METHOD QUESTION 
    */
    /**
    * SAVE DATA
    */
    async setData(){
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.$store.state.process.run = true
        let data = {}
        this.$store.state.process.run = true
        data.options = []
        this.questions.options.forEach((opt,index) => {
          data.options.push({
            media: [],
            text: opt.text
          })
        })
        data.instruction_text = this.questions.instruction;
        data.instruction_media = "";
        data.type = "single_choice_hint";
        data.question_text = this.questions.question;
        data.question_media = "";
        data.point = parseInt(this.questions.point);
        data.timer= parseInt(this.questions.timer);
        data.correct_answer = [this.questions.selectedValue];
        data.hint = {
          text: this.questions.paragraph,
          media: []
        }
        data.status = "publish"
        data.explanation = {
          true_text: this.questions.correct_explan,
          true_media: "",
          false_text: this.questions.incorrect_explan,
          false_media: ""
        }
        if (this.questions.id !== "") {
          this.save(`update`, `bank/v1/question/${this.questions.id}`, `api/v1/question/update/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.questions.id}` , data)
        }else {
          this.save(`create`, `bank/v1/question/`, `api/v1/question/add/${this.$route.params.id_topic}/${this.$route.params.id_activity}`, data)
        }
      }else {
        this.$store.state.process.run = false
        this.$refs.snackbar.open("error", `Form Belum Valid`);
      }
    },
    async save(params, url_question, url_activity, data_send){
      this.$store.state.process.run = true
      if(params == `update`){
        await put(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            put(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                Object.assign(this.questions, {id: res.data.id})
                this.$refs.snackbar.open("#4CAF50", `New Question Updated Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Updated Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Updated Failed`);
          }
        })
      }else {
        await post(url_question,{
          data: data_send
        })
        .then((response) => {
          let res = response.data
          if (res.status == 200) {
            post(url_activity,{
              data: {
                id: res.data.id,
                is_public: false,
                is_paid: true
              }
            })
            .then(response => {
              let res = response.data
              if (res.status == 200) {
                Object.assign(this.questions, {id: res.data.id})
                this.$refs.snackbar.open("#4CAF50", `New Question Added Succesfully`);
                setTimeout(() => {
                  this.$store.state.process.run = false
                  this.$router.push(`/questions/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`)
                }, 1000);
              }else{
                this.$store.state.process.run = false
                this.$refs.snackbar.open("error", `New Question Added Failed`);
              }
            })
          }else{
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `New Question Added Failed`);
          }
        })
      }
    },
    /**
     * SAVE DATA END 
    */
  },
}
</script>

<style>
/* .v-messages__message {
  line-height: 20px !important;
  font-size: 0.900rem !important;
  text-align: end !important;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
} */
</style>